/*
Template Name: Skote - Admin & Dashboard Template
Author: Themesbrand
Version: 3.1.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/


//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/dragula";
@import "custom/plugins/session-timeout";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";

@import "custom/plugins/switch";
@import "custom/plugins/colorpicker";
@import "custom/plugins/timepicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/datatable";
@import "custom/plugins/responsive-table";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/echarts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";
@import "custom/plugins/leaflet-maps";


// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/file-manager";
@import "custom/pages/chat";
@import "custom/pages/projects";
@import "custom/pages/contacts";
@import "custom/pages/crypto";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";



// .MuiTableRow-root:nth-child(odd){
//     background-color: #f8f9fa;
// }
// .MuiTableCell-head{
//     font-weight: 700 !important;
// }
// p.css-levciy-MuiTablePagination-displayedRows{
//     margin-bottom: 0;
// }
body{
    padding-right: 0 !important;
}
.ag-theme-alpine .ag-row-even:not(.ag-row-selected){
    background-color: #f0f0f0 !important;
}
.ag-theme-alpine .ag-row-even:not(.ag-row-selected):hover{
    background-color: rgba(33,150,243,0.1) !important;
}
.ag-theme-alpine .ag-root-wrapper{
    border: 0px !important;
}
.ag-theme-alpine .ag-header{
    border: 0px !important;
}
.ag-theme-alpine .ag-paging-panel{
    border: 0px !important;
}
.ag-theme-alpine .ag-cell-inline-editing {
    height: 100% !important;
}
.ag-theme-alpine .ag-cell-inline-editing .ag-react-container input{
    margin-top: 0.65em;
}
.ag-theme-alpine .ag-popup-editor{
    margin-top: 0.6em;
}


.ag-theme-alpine-dark .ag-root-wrapper-body{
    background-color: #242424;
}
.ag-theme-alpine-dark .ag-header-row, .ag-theme-alpine-dark .ag-paging-panel{
    background-color: #242424;
}
.ag-theme-alpine-dark .ag-row-even:not(.ag-row-selected){
    background-color: #2e2e2e !important;
}
.ag-theme-alpine-dark .ag-row-odd:not(.ag-row-selected){
    background-color: #303030 !important;
}
.ag-theme-alpine-dark .ag-row-even:not(.ag-row-selected):hover{
    background-color: rgba(33,150,243,0.1) !important;
}
.ag-theme-alpine-dark .ag-row-odd:not(.ag-row-selected):hover{
    background-color: rgba(33,150,243,0.1) !important;
}
.ag-theme-alpine-dark .ag-root-wrapper{
    border: 0px !important;
}
.ag-theme-alpine-dark .ag-header{
    border: 0px !important;
}
.ag-theme-alpine-dark .ag-paging-panel{
    border: 0px !important;
}
.ag-theme-alpine-dark .ag-cell-inline-editing {
    height: 100% !important;
}
.ag-theme-alpine-dark .ag-cell-inline-editing .ag-react-container input{
    margin-top: 0.65em;
}
.ag-theme-alpine-dark .ag-popup-editor{
    margin-top: 0.6em;
}
.MuiList-root.MuiMenu-list{
  padding: 10px 15px;
  min-width: 120px;
}
.MuiList-root.MuiMenu-list .dropdown-item{
  padding-top: 5px;
  padding-bottom: 5px;
}

.bg-macl-blue {
    background-color:#202945;
}
#modalBody{
    padding-left: 4vh;
    padding-right: 4vh;
}

